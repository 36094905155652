import { AspectRatioWrapper, ResponsiveImage, Modal } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import ActionBarMaybe from './ActionBarMaybe';
import ImageCarousel from './ImageCarousel/ImageCarousel';
import css from './ListingPage.module.css';
import React from 'react';

const SectionImages = (props) => {
    const {
        title,
        listing,
        isOwnListing,
        editParams,
        handleViewPhotosClick,
        imageCarouselOpen,
        onImageCarouselClose,
        onManageDisableScrolling,
    } = props;

    const hasImages = listing.images && listing.images.length > 0;
    const firstImage = hasImages ? listing.images[0] : null;
    const {
        aspectWidth,
        aspectHeight,
        variantPrefix = 'listing-card',
    } = config.listing;
    const variants = firstImage
        ? Object.keys(firstImage?.attributes?.variants).filter((k) =>
              k.startsWith(variantPrefix),
          )
        : [];

    // Action bar is wrapped with a div that prevents the click events
    // to the parent that would otherwise open the image carousel
    const actionBar = listing.id ? (
        <div onClick={(e) => e.stopPropagation()}>
            <ActionBarMaybe
                className={css.actionBarForHeroLayout}
                isOwnListing={isOwnListing}
                listing={listing}
                editParams={editParams}
            />
        </div>
    ) : null;

    const viewPhotosButton = hasImages ? (
        <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
            <FormattedMessage
                id="ListingPage.viewImagesButton"
                values={{ count: listing.images.length }}
            />
        </button>
    ) : null;

    return (
        <div className={css.sectionImages}>
            <AspectRatioWrapper
                className={css.imageWrapperForSectionImage}
                width={aspectWidth}
                height={aspectHeight}
                onClick={handleViewPhotosClick}>
                {actionBar}
                <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                />
                {viewPhotosButton}
            </AspectRatioWrapper>
            <Modal
                id="ListingPage.imageCarousel"
                scrollLayerClassName={css.carouselModalScrollLayer}
                containerClassName={css.carouselModalContainer}
                lightCloseButton
                isOpen={imageCarouselOpen}
                onClose={onImageCarouselClose}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}>
                <ImageCarousel images={listing.images} />
            </Modal>
        </div>
    );
};

export default SectionImages;
